import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { isEmpty, path } from 'ramda';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';
import HighlightCard from '@greenberry/salal/components/HighlightCard';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';
import Heading from '@greenberry/salal/components/Heading';
import Text from '@greenberry/salal/components/Text';

import SlideIntoView from '../../../components/SlideIntoView';
import PartnershipLayout from '../../../components/PartnershipLayout';
import themes from '../../../constants/themes';
import parseTooltip from '../../../utils/parseTooltip';
import translateTheme from '../../../utils/translateTheme';

const Start = ({
  pageContext: { dato, partnership, sector, copy, ...pageContext },
  location,
}) => {
  const images = useStaticQuery(graphql`
    {
      students: file(relativePath: { eq: "leerlingen.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      financial: file(relativePath: { eq: "financien.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      organization: file(relativePath: { eq: "organisatie.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const _partnership = Object.assign({}, dato, partnership);
  return (
    <PartnershipLayout
      partnership={_partnership}
      seoMetaTags={copy.seoMetaTags}
      sector={sector}
      {...pageContext}
    >
      {!isEmpty(_partnership.startIntroText) && (
        <Section style={{ backgroundColor: 'transparent' }}>
          <Introduction
            title="Onze missie en visie"
            text={parseTooltip(_partnership.startIntroText)}
          />
        </Section>
      )}
      {themes.map((key, index) => (
        <Section size={'2'} key={key}>
          <SlideIntoView minHeight="250px">
            <HighlightCard
              node={
                <Img fluid={path([key, 'childImageSharp', 'fluid'], images)} />
              }
              orientation={index % 2 === 0 ? 'ltr' : 'rtl'}
            >
              <Heading appearance="secondary" weight="semibold" size="xxxl">
                {parseTooltip(copy[`${key}Heading`])}
              </Heading>
              <Text size="l">{parseTooltip(copy[`${key}Body`])}</Text>
              <Link to={`${location.pathname}${translateTheme(key)}`}>
                <Button type="button">
                  <span>Lees meer</span>
                  <Icon size="lg" type="arrowCircleRight" />
                </Button>
              </Link>
            </HighlightCard>
          </SlideIntoView>
        </Section>
      ))}
    </PartnershipLayout>
  );
};

Start.propTypes = {
  pageContext: PropTypes.shape({
    dato: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string.isRequired,
      startIntroText: PropTypes.string,
    }).isRequired,
    partnership: PropTypes.shape({
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      website: PropTypes.string.isRequired,
      externalId: PropTypes.string.isRequired,
    }).isRequired,
    sector: PropTypes.string.isRequired,
    copy: PropTypes.shape({
      studentsHeading: PropTypes.string.isRequired,
      studentsBody: PropTypes.string.isRequired,
      financialHeading: PropTypes.string.isRequired,
      financialBody: PropTypes.string.isRequired,
      organizationHeading: PropTypes.string.isRequired,
      organizationBody: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

Start.defaultProps = {
  location: { pathname: '/' },
};

export default Start;

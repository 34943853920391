import React from 'react';
import { map, isEmpty } from 'ramda';
import Tooltip from '@greenberry/salal/components/Tooltip';
import theme from '@greenberry/salal/theme';

const splitTip = tip => {
  const matches = /{([\w() -|\s]+)\|([\w() -|\s]+)}/g.exec(tip);
  return {
    label: matches[1],
    tip: matches[2],
  };
};

const parseTooltip = str => {
  if (isEmpty(str) || !str) {
    return str;
  }

  const regex = /({[\w() -|\s]+\|[\w() -|\s]+})/g;
  const chunks = str.split(regex);

  return map(chunk => {
    const key = chunk.substr(0, Math.round(Math.random() * chunk.length));
    if (regex.test(chunk)) {
      const { label, tip } = splitTip(chunk);

      return (
        <Tooltip key={key} breakpoint={theme.bp('2')} tip={tip}>
          {label}
        </Tooltip>
      );
    }

    return <span key={key}>{chunk}</span>;
  }, chunks);
};

export default parseTooltip;
